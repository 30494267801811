import { render, staticRenderFns } from "./Useful.vue?vue&type=template&id=e26e04fa&scoped=true"
import script from "./Useful.vue?vue&type=script&lang=js"
export * from "./Useful.vue?vue&type=script&lang=js"
import style0 from "./Useful.vue?vue&type=style&index=0&id=e26e04fa&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e26e04fa",
  null
  
)

/* custom blocks */
import block0 from "@/locales/useful.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fopt%2Fbuild%2Frepo%2Fsrc%2Fviews%2Fuseful%2FUseful.vue&external"
if (typeof block0 === 'function') block0(component)
import block1 from "@/locales/articles-card-content.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fopt%2Fbuild%2Frepo%2Fsrc%2Fviews%2Fuseful%2FUseful.vue&external"
if (typeof block1 === 'function') block1(component)

export default component.exports